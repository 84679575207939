import { format } from 'date-fns'
import { Link, navigate } from 'gatsby'
import React from 'react'
import {
  getConversationUrl,
  getFluidProps,
  getPreviewAriaLabel,
  getPreviewImageAltText,
  isBrowser,
  isTopOut,
} from '../lib/helpers'
import Img from 'gatsby-image'
import PortableText from './portableText'
import { nanoid } from 'nanoid'

class CollaborationPreview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isScrolled: false,
      isBottom: false,
      isContainerLinkActive: true,
    }
    this.containerRef = React.createRef()
    this.textInnerRef = React.createRef()
  }

  onScroll = () => {
    const containerEl = this.containerRef.current
    const textInnerEl = this.textInnerRef.current
    if (containerEl && textInnerEl) {
      const textInnerElHeight = textInnerEl.clientHeight + 16
      const containerBottomToTop = containerEl.getBoundingClientRect().bottom
      const textBelowContainer = textInnerElHeight + 16 >= containerBottomToTop
      this.setState({
        isScrolled: isTopOut(containerEl, 16),
        isBottom: textBelowContainer,
      })
    }
  }

  getImageSize = () => {
    const { previewImageSize, customPreviewImageSize } = this.props
    const main = previewImageSize && previewImageSize.widthDesktop
    const custom = customPreviewImageSize && customPreviewImageSize.widthDesktop
    return custom ? custom : main ? main : 'is-m-landscape'
  }

  render() {
    const {
      title,
      _rawFormattedTitle,
      artist,
      isInList,
      index,
      slug,
    } = this.props
    const { isScrolled, isBottom, isContainerLinkActive } = this.state
    const image = this.props.rawImageReference ? this.props._rawMainImage : this.props.mainImage
    const imageSize = this.getImageSize()
    const tags = this.props._rawTags ? this.props._rawTags : this.props.tags
    const fluidProps = image && getFluidProps(image, 2400)
    const ariaLabel = getPreviewAriaLabel(title, artist)
    const previewImageAltText = getPreviewImageAltText(title, artist)

    return (
      <div
        className={`conversation-preview preview ${isInList ? 'is-in-list' : 'is-pb'} ${
          index ? 'is-pb-index-' + index : ''
        }
        ${imageSize ? 'image-sizing--' + imageSize : ''}`}
        ref={this.containerRef}
        aria-label={ariaLabel}
        onClick={isContainerLinkActive ? () => navigate(getConversationUrl(slug.current)) : null}
      >
        <div
          className={`conversation-preview__text
        ${isScrolled ? 'is-container-scrolled' : ''} ${isBottom ? 'is-bottom' : ''}
        `}
        >
          <div ref={this.textInnerRef}>
            <Link to={getConversationUrl(slug.current)} aria-label={ariaLabel}>
              <div className="conversation-preview__title">
                {title && artist && (
                  <>
                    <span>
                      <em>
                        {_rawFormattedTitle ? (
                          <PortableText blocks={_rawFormattedTitle} />
                        ) : (
                          <>
                            {title}
                            <br />
                          </>
                        )}
                      </em>
                      {artist}
                    </span>
                    <br />
                  </>
                )}
              </div>
            </Link>
            <div className="conversation-preview__tags">
              {tags &&
                tags.map((tag, i) => (
                  <div className="btn content-tag content-tag--info" key={nanoid()}>
                    {tag.title}
                  </div>
                ))}
              {!isInList && (
                <Link
                  className="btn content-tag content-tag--link"
                  to="/conversations"
                  aria-label="Conversations overview"
                  onMouseEnter={() => this.setState({ isContainerLinkActive: false })}
                  onMouseLeave={() => this.setState({ isContainerLinkActive: true })}
                >
                  Balkan...Conversations
                </Link>
              )}
            </div>
          </div>
        </div>
        <Link
          className={`conversation-preview__image ${imageSize}`}
          to={getConversationUrl(slug.current)}
          aria-label={ariaLabel}
        >
          {fluidProps && <Img fluid={fluidProps} alt={previewImageAltText} />}
        </Link>
      </div>
    )
  }
  componentDidMount() {
    if (!isBrowser()) {
      return
    }
    document.addEventListener('scroll', this.onScroll)
  }
  componentWillUnmount() {
    document.addEventListener('scroll', this.onScroll)
  }
}

export default CollaborationPreview
