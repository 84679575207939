import React from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import ContentContainer from '../containers/content'
import SEO from '../components/seo'
import { mapEdgesToNodes } from '../lib/helpers'
import { Helmet } from 'react-helmet'
import ConversationPreviewList from '../components/conversation-preview-list'

// export default function() {
//   return <h1>hihih</h1>
// }

export const query = graphql`
  query ConversationsIndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      openGraph {
        image {
          ...SanityImage
        }
      }
    },
    conversations: allSanityConversation(
      sort: { fields: [releaseDate], order: DESC }
      filter: { slug: { current: { ne: null } }, isVisible: { eq: true } }
    ) {
      edges {
        node {
          id
          title
          artist
          mainImage {
            ...SanityImage
            alt
          }
          previewImageSize {
            widthDesktop
          }
          tags {
            _id
            title
          }
          slug {
            current
          }
          isVisible
          _rawFormattedTitle
        }
      }
    }
  }
`

const ConversationsIndexPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Container>
        <GraphQLErrorList errors={errors} />
      </Container>
    )
  }

  const site = (data || {}).site
  const ogImage = site.openGraph.image
  const conversationNodes = data?.conversations
    ? mapEdgesToNodes(data.conversations)
    : []

  if (!site) {
    console.warn(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <>
      <Helmet>
        <html className="is-bg-black" />
      </Helmet>
      <ContentContainer navMenuTitle="Conversations" section="conversations" bgBlack>
        <SEO
          title="Balkan...Conversations"
          description={site.description || ''}
          keywords={site.keywords || []}
          image={ogImage}
        />
        <div className="page-container is-collaborations-overview">
          <h1 hidden>{site.title}</h1>
          <div>{conversationNodes && <ConversationPreviewList nodes={conversationNodes} />}</div>
        </div>
      </ContentContainer>
    </>
  )
}

export default ConversationsIndexPage
